import { useState } from 'react'
import { BackgroundImage, Box, Button, Center, Grid, Text } from '@mantine/core'
import { Carousel } from '@mantine/carousel'
import { changeColorButton2 } from '../../styles/style'
import { IconArrowLeft, IconArrowRight } from '@tabler/icons-react'

const kitchen = [...Array(5)].map((_,i) => require(`../../image/cataloge/carousel_kitchen/${i}.jpg`))
const garderob = [...Array(5)].map((_,i) => require(`../../image/cataloge/carousel_garderob/${i}.jpg`))
const gostinaya = [...Array(3)].map((_,i) => require(`../../image/cataloge/carousel_gostinaya/${i}.jpg`))
const shkaf = [...Array(3)].map((_,i) => require(`../../image/cataloge/carousel_shkaf/${i}.jpg`))
const tables = [...Array(5)].map((_,i) => require(`../../image/cataloge/carousel_tables/${i}.jpg`))
const stoleshnitsa = [...Array(3)].map((_,i) => require(`../../image/cataloge/carousel_stoleshnitsi/${i}.jpg`))
const sanYzli = [...Array(5)].map((_,i) => require(`../../image/cataloge/carousel_toilets/${i}.jpg`))
const CarouselData = [
    {
        id: 0,
        title: 'Кухни',
        description: 'Каталог кухонь на нашем сайте представляет широкий выбор стильных и функциональных кухонных решений для вашего дома',
        imageURL: kitchen,
        pathURL: '/kitchen'
    },
    {
        id: 1,
        title: 'Гардеробные',
        description: 'Мы предлагаем гардеробы с различными отделениями, полками, ящиками и вешалками, чтобы обеспечить удобное и организованное хранение вашей одежды и аксессуаров',
        imageURL: garderob,
        pathURL: '/garderob'
    },
    {
        id: 2,
        title: 'Шкафы',
        description: 'Создайте удивительный порядок в вашем доме с нашими шкафами, обеспечивающими не только эффективное хранение, но и эстетическую привлекательность',
        imageURL: shkaf,
        pathURL: '/shkaf'
        
    },
    {
        id: 3,
        title: 'Гостиные',
        description: 'Создайте свой уникальный стиль с нашими гардеробными, которые не только удовлетворяют вашим потребностям в организации, но и подчеркивают красоту вашего интерьера',
        imageURL: gostinaya,
        pathURL: '/gostinaya'
        
    },
    {
        id: 4,
        title: 'Столешницы',
        description: 'Разнообразие стилей, функциональность и качество столешниц в каждой детали. Найдите идеальное решение для вашего стиля жизни и интерьера',
        imageURL: stoleshnitsa,
        pathURL: '/stoleshnitsa'
        
    },
    {
        id: 5,
        title: 'Столы и стулья',
        description: 'В нашей студии мебели мы предлагаем изысканные столы и стулья, которые идеально сочетают стиль и функциональность. Каждый предмет создан с учетом современных тенденций и различных интерьерных решений',
        imageURL: tables,
        pathURL: '/tables'
        
    },
    {
        id: 6,
        title: 'Сан. Узлы',
        description: 'В нашей студии мебели мы предлагаем стильные и функциональные решения для санузлов, которые созданы с акцентом на комфорт и эстетику',
        imageURL: sanYzli,
        pathURL: '/san-yzli'
    },
]

interface ComponentProps {
    id: number,
    title: string,
    description: string,
    imageURL: any,
    pathURL: string,
}



const PropsContainer: React.FC<ComponentProps> = ({id, title, description, imageURL, pathURL}) => {
    return (
        <Box h={500} key={id} pos={'relative'}>
                <BackgroundImage w={'100%'} h={'100%'} src={imageURL[0]}>
                    <Box w={'100%'} h={'100%'} bg={`rgba(0, 0, 0, 0.4)`}>
                        <Box w={280} h={'3rem'} bg={'#10222E'}>
                            <Center pt={'0.25rem'}>
                                <Text ff={'Roboto Condensed'} fz={24} fw={500} color='#FFF'>
                                    {title}
                                </Text>
                            </Center>
                        </Box>
                        <Text color='#FFF' maw={'80%'} display={'block'} mx={'auto'} mt={'10rem'} ta={'center'}>
                                {description}
                        </Text>
                        <Box pos={'absolute'} bottom={0} w={'100%'} display={'block'}>
                            <Button component='a' href={pathURL} w={`80%`} h={'3rem'} mx={'auto'}  mb={'3rem'} sx={{
                                    border: `4px solid #FFF`,
                                    backgroundColor: 'rgba(1,1,1,0)',
                                    display: 'block',
                                    '&:hover': {
                                        backgroundColor: '#FFF',
                                        color: '#10222E',
                                        animation: `${changeColorButton2} 750ms`
                                    }
                                    
                                    }}>
                                    <Text>
                                        Перейти
                                    </Text>
                            </Button>
                        </Box>
                    </Box>
                </BackgroundImage>
            </Box>
    )
}

// const PropsContainer: React.FC<ComponentProps> = ({id, title, description, imageURL, pathURL}) => {
//     const [currentIndex, setCurrentIndex] = useState('');
//     const PropsSlide: React.FC = () => {
//         return (
//             <Carousel.Slide key={id}>
//                     <BackgroundImage w={'100%'} h={'100%'} src={imageURL[currentIndex]}>
//                         <Box w={'100%'} h={'100%'} bg={`rgba(0, 0, 0, 0.3)`}>
//                             <Box w={280} h={'3rem'} bg={'#10222E'}>
//                                 <Center pt={'0.25rem'}>
//                                     <Text ff={'Roboto Condensed'} fz={24} fw={500} color='#FFF'>
//                                         {title}
//                                     </Text>
//                                 </Center>
//                             </Box>
//                             {/* <Center>
//                                 <Text ff={'Roboto'} color='#FFF' lh={'normal'} mt={id < 2 || id > 4 ? 170 : 150} maw={id < 2 || id > 4 ? 360 : 270}>
//                                     {description}
//                                 </Text>
//                             </Center> */}
//                             <Center>
//                                 <Button component='a' href={pathURL} w={`75%`} h={'3rem'} mx={80} pos={'absolute'} bottom={0} mb={'3rem'} sx={{
//                                     border: `4px solid #FFF`,
//                                     backgroundColor: 'rgba(1,1,1,0)',
                                    
//                                     '&:hover': {
//                                         backgroundColor: '#FFF',
//                                         color: '#10222E',
//                                         animation: `${changeColorButton2} 750ms`
//                                     }
                                    
//                                     }}>
//                                     <Text>
//                                         Перейти
//                                     </Text>
//                                 </Button>
//                             </Center>
//                         </Box>
//                     </BackgroundImage>
//                 </Carousel.Slide>
//         )
//     }

//     const handleSlideChange = (index: any) => {
//         setCurrentIndex(index);
//         // console.log(index)
//     };
//     return (
//         <Carousel
//             key={id}
//             mx="auto"
//             withIndicators
//             height={500}
//             onSlideChange={handleSlideChange}
//             previousControlIcon={<IconArrowLeft color='#FFF' size={42}/>}
//             nextControlIcon={<IconArrowRight color='#FFF' size={42}/>}>
//             {id > 1 && id < 5
//             ?
//             <>
//                 <PropsSlide />
//                 <PropsSlide />
//                 <PropsSlide />
//             </>        
//             :
//             <>
//                 <PropsSlide />
//                 <PropsSlide />
//                 <PropsSlide />
//                 <PropsSlide />
//                 <PropsSlide />
//             </>
//             }
//         </Carousel>
//     )
// }

const CarouselContainer: React.FC = () => {
    return (
        <>
        {CarouselData.map((item) => (
            <>
                {item.id == 0
                &&
                <Grid.Col sm={12} md={6} lg={6} key={item.id}>
                  <PropsContainer id={item.id} title={item.title} description={item.description} imageURL={item.imageURL} pathURL={item.pathURL}/>         
                </Grid.Col>
                }
                {item.id == 1
                &&
                <Grid.Col sm={6} md={6} lg={6} key={item.id}>
                  <PropsContainer id={item.id} title={item.title} description={item.description} imageURL={item.imageURL} pathURL={item.pathURL}/>         
                </Grid.Col>
                }
                {item.id > 1 && item.id < 5
                &&
                <Grid.Col sm={6} md={4} lg={4} key={item.id}>
                  <PropsContainer id={item.id} title={item.title} description={item.description} imageURL={item.imageURL} pathURL={item.pathURL}/>         
                </Grid.Col>
                }
                {item.id > 4
                &&
                <Grid.Col sm={6} md={6} lg={6} key={item.id}>
                  <PropsContainer id={item.id} title={item.title} description={item.description} imageURL={item.imageURL} pathURL={item.pathURL}/>         
                </Grid.Col>
                }
            </>
        ))}
        </>
    )
}

export const CatalogeCarousel = () => {
  return (
    <CarouselContainer />
  )
}